import angular from 'angular';

import templateUrl from './template.html';

export default angular
  .module('wcCommon.components.profile.member.aspirations', [])
  .component('wcMemberProfileAspirations', {
    bindings: {
      member: '<'
    },
    templateUrl: templateUrl
  });
