import './BooleanChip.scss';
import BooleanChipTemplateUrl from './BooleanChip.html';

let booleanChip = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: BooleanChipTemplateUrl,
    scope: {
      active: '=',
      title: '@',
      falseTitle: '@?'
    }
  };
};

booleanChip.NAME = 'booleanChip';

export default booleanChip;
