import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

/*
Professional Summary component
clampLines: 2-4, optional, default: 2
 */

class ProfessionalSummaryController {
  constructor() {
    this.lines = this.clampLines ? this.clampLines : 2;
    this.clampStyle = 'Clamp-' + this.lines;
    this.professionalSummaryExpanded = false;
  }
}

export default angular
  .module('wcCommon.components.professionalSummary', [])
  .component('wcProfessionalSummary', {
    bindings: {
      member: '<',
      clampLines: '@?'
    },
    controller: ProfessionalSummaryController,
    templateUrl: templateUrl
  });
