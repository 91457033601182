import angular from 'angular';

import templateUrl from './template.html';

class MemberProfileExperienceController {
  /*@ngInject*/
  constructor(API) {
    this.API = API;
  }
}
export default angular
  .module('wcCommon.components.profile.member.experience.limited', [])
  .component('wcMemberProfileExperienceLimited', {
    bindings: {
      member: '<'
    },
    controller: MemberProfileExperienceController,
    templateUrl: templateUrl
  });
