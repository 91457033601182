import angular from 'angular';

import templateUrl from './template.html';

class CompensationHiddenController {
  /*@ngInject*/
  constructor(CompensationsBlockedService) {
    this.CompensationsBlockedService = CompensationsBlockedService;
    this._getCompensationsBlocked();
  }

  _getCompensationsBlocked() {
    this.CompensationsBlockedService.isReady.then(
      this._setCompensationsBlocked.bind(this)
    );
  }

  _setCompensationsBlocked() {
    this.compensationsBlocked = this.CompensationsBlockedService.isBlocked();
  }
}

export default angular
  .module('wc.components.compensation-hidden', [])
  .component('wcCompensationHidden', {
    controller: CompensationHiddenController,
    templateUrl: templateUrl
  });
