import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class remunerationOverviewController {
  /*@ngInject*/
  constructor(API, $mdConstant, $timeout, $q, CurrencyService, $scope) {
    this.API = API;
    this.$mdConstant = $mdConstant;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$scope = $scope;
    this.loading = true;
    this.remunerationExpanded = false;
    this.CurrencyService = CurrencyService;
  }

  $onInit() {
    if (this.remuneration) {
      this.CurrencyService.isReady
        .then(this.setView.bind(this))
        .catch(this.handleError.bind(this));
    } else {
      this.loading = false;
    }
  }

  isExpandable() {
    return !this.remuneration.company;
  }

  expandRemuneration() {
    if (this.isExpandable()) {
      this.remunerationExpanded = !this.remunerationExpanded;
    }
  }

  setView() {
    this.remuneration.totalCompensation = this.getTotalCompensation();
    this.selectedCurrency = this.CurrencyService.getCurrencySymbol(
      this.remuneration.totalCompensationCurrency
    );
    if (this.isExpandable()) {
      this.formatBenefitChips();
    }
    this.loading = false;
  }

  formatBenefitChips() {
    this.benefits = this.remuneration.other.map(benefit => {
      return { name: benefit };
    });
  }

  // TODO: This should live in a service
  numberOrZero(numberish) {
    let number = +numberish;
    return isNaN(number) ? 0 : number;
  }

  getTotalCompensation() {
    let amounts = [
      this.remuneration.salaryAmount,
      this.remuneration.bonusAmount,
      this.remuneration.longTermCashAmount,
      this.remuneration.stockAndOptionsAmount,
      this.remuneration.otherFinancialAmount
    ];
    return amounts.reduce(
      (a, b) => this.numberOrZero(a) + this.numberOrZero(b),
      0
    );
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

export default angular
  .module('wcCommon.components.remuneration.overview', [])
  .component('remunerationOverview', {
    templateUrl: templateUrl,
    bindings: {
      remuneration: '<'
    },
    controller: remunerationOverviewController
  });
