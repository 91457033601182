import angular from 'angular';

import templateUrl from './template.html';

class MemberProfileFinancePeopleController {
  /*@ngInject*/
  constructor(CurrencyService) {
    this.CurrencyService = CurrencyService;
  }

  $onInit() {
    this.setManagementStats();
  }

  hasCurrencyValue(val) {
    return val && parseFloat(val) > 0;
  }

  hasAnyValue() {
    return !!(
      this.hasCurrencyValue(this.member.capexAmount) ||
      this.hasCurrencyValue(this.member.opexAmount) ||
      this.member.fullTimeEmployees ||
      this.member.directReports ||
      this.member.extendedTeamSize
    );
  }

  setManagementStats() {
    this.managementStats = [
      {
        label: 'Capital Expenditure',
        value:
          this.member.capexAmount !== null
            ? parseFloat(this.member.capexAmount)
            : 0,
        currencySymbol: this.CurrencyService.getCurrencySymbol(
          this.member.capexCurrency
        )
      },
      {
        label: 'Operational Expenditure',
        value:
          this.member.opexAmount !== null
            ? parseFloat(this.member.opexAmount)
            : 0,
        currencySymbol: this.CurrencyService.getCurrencySymbol(
          this.member.opexCurrency
        )
      },
      {
        label: 'Profit and Loss',
        value:
          this.member.profitAndLossAmount !== null
            ? parseFloat(this.member.profitAndLossAmount)
            : 0,
        currencySymbol: this.CurrencyService.getCurrencySymbol(
          this.member.profitAndLossCurrency
        )
      },
      {
        label: 'Revenue Target',
        value:
          this.member.revenueTargetAmount !== null
            ? parseFloat(this.member.revenueTargetAmount)
            : 0,
        currencySymbol: this.CurrencyService.getCurrencySymbol(
          this.member.revenueTargetCurrency
        )
      },
      { label: 'Number of FTEs', value: this.member.fullTimeEmployees },
      { label: 'Number of Direct Reports', value: this.member.directReports },
      { label: 'Extended Team', value: this.member.extendedTeamSize }
    ];
  }
}

export default angular
  .module('wcCommon.components.profile.member.financePeople', [])
  .component('wcMemberProfileFinancePeople', {
    bindings: {
      member: '<'
    },
    controller: MemberProfileFinancePeopleController,
    templateUrl: templateUrl
  });
