import angular from 'angular';

import templateUrl from './template.html';

export default angular
  .module('wcCommon.components.profile.member.experience.full', [])
  .component('wcMemberProfileExperienceFull', {
    bindings: {
      member: '<',
      user: '<',
      isClient: '<?'
    },
    controller: function() {
      this.$onInit = function() {
        this.showContacts = this.isClient || (this.user && this.user.email);
      };
    },
    templateUrl: templateUrl
  });
