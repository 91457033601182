import angular from 'angular';

import templateUrl from './template.html';

class ProfileContentController {
  /*@ngInject*/
  constructor() {
    if (!this.member || !this.member.answers || !this.member.answers.length) {
      return;
    }

    this.member.answers = this.member.answers.filter(x => {
      return !!x.text;
    });
  }

  hasAspirations() {
    return (
      (this.member.aspirations &&
        (this.member.aspirations.goals ||
          (this.member.aspirations.industryStandardJobTitles &&
            this.member.aspirations.industryStandardJobTitles.length) ||
          (this.member.aspirations.industries &&
            this.member.aspirations.industries.length) ||
          (this.member.aspirations.cities &&
            this.member.aspirations.cities.length) ||
          (this.member.aspirations.companies &&
            this.member.aspirations.companies.length))) ||
      this.member.openToRelocate ||
      this.member.aspirationalComp ||
      this.member.interestedInNonExec
    );
  }

  showAspirations() {
    return (this.isOwn || this.isClient) && this.hasAspirations();
  }

  showInsights() {
    return (
      (this.member.hobbies && this.member.hobbies.length) ||
      (this.member.answers && this.member.answers.length)
    );
  }
}

const wcMobileMemberProfileContent = {
  bindings: {
    member: '<',
    user: '<',
    isOwn: '=?',
    isClient: '<?'
  },
  controller: ProfileContentController,
  templateUrl: templateUrl
};

export default angular
  .module('wcMobile.components.profile.member.content', [])
  .component('wcmMemberProfileContent', wcMobileMemberProfileContent);
