import angular from 'angular';
import moment from 'moment';

import './styles.scss';
import templateUrl from './template.html';

class MinimalPositionOverviewController {
  dateDiff(start, end = {}) {
    let diff = moment(end).diff(moment(start), true);
    if (diff < 0) {
      diff *= -1;
    }
    let duration = moment.duration(diff);
    return duration.format();
  }

  getPositionType(position) {
    if (position.positionType) {
      return position.positionType.displayName
        ? position.positionType.displayName
        : position.positionType;
    }
  }
}

export default angular
  .module('wcCommon.components.position.minimalPositionOverview', [])
  .component('wcMinimalPositionOverview', {
    bindings: {
      position: '<'
    },
    controller: MinimalPositionOverviewController,
    templateUrl: templateUrl
  });
