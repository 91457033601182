import angular from 'angular';

import templateUrl from './template.html';

import { removeChildRegions } from '../../../services/Regions';

export default angular
  .module('wcCommon.components.profile.member.workEligibility', [])
  .component('wcMemberProfileWorkEligibility', {
    bindings: {
      member: '<'
    },
    controller: function() {
      this.$onInit = function() {
        this.member.eligibleRegions = removeChildRegions(
          this.member.eligibleRegions
        );
      };
    },
    templateUrl: templateUrl
  });
