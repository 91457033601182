import imageBubble from './ImageBubble.directive';
import imageBubbleList from './ImageBubbleList.directive';
import MissingImage from '../missing-image';

const dependencies = [MissingImage.name];

export default angular
  .module('wcCommon.components.imagebubble', dependencies)
  .directive(imageBubble.NAME, imageBubble)
  .directive(imageBubbleList.NAME, imageBubbleList);
