import './StatsWidget.scss';
import StatsWidgetTemplateUrl from './StatsWidget.html';

let statsWidget = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: StatsWidgetTemplateUrl,
    scope: {
      stats: '='
    },
    link: scope => {
      scope.stats.forEach(stat => {
        stat.isNumerical = angular.isNumber(stat.value);
      });
      scope.stats = scope.stats.filter(stat => stat.value);
      const firstColumnLastItemIndex = Math.ceil(scope.stats.length / 3);
      const secondColumnLastItemIndex = Math.ceil((scope.stats.length * 2) / 3);
      scope.column1 = scope.stats.slice(0, firstColumnLastItemIndex);
      scope.column2 = scope.stats.slice(
        firstColumnLastItemIndex,
        secondColumnLastItemIndex
      );
      scope.column3 = scope.stats.slice(secondColumnLastItemIndex);
    }
  };
};

statsWidget.NAME = 'statsWidget';

export default statsWidget;
