import angular from 'angular';

import autoScrollContainer from './autoscrollContainer';
import blockQuote from './blockquote/BlockQuote.module';
import buttons from './buttons';
import chips from './chips';
import circularImage from './circularimage/CircularImage.module';
import clamp from './clamp';
import clampedText from './clamped-text/index';
import companyLogo from './companylogo';
import compensationHidden from './compensation-hidden';
import cookieBar from './cookiebar';
import educationOverview from './educationoverview/EducationOverview.module';
import errorViews from './errorViews';
import expandableTextarea from './expandableTextarea';
import highlightedImageBubble from './highlightedImageBubble';
import icon from './icon';
import imageBubble from './imagebubble';
import inputs from './inputs';
import logo from './logo';
import logomh from './logo-mh';
import memberProfile from './member-profile/index';
import messenger from './messenger/Messenger.module';
import minimalPositionOverview from './position/minimal-position-overview';
import missingImage from './missing-image';
import momentDurationFormatWrapper from './moment-duration-format-wrapper';
import navbar from './navbar';
import nonExecPositionOverview from './position/non-exec-position-overview';
import overviewCard from './overviewcard';
import passwordStrengthMeter from './passwordstrengthmeter';
import places from './places/Places.module';
import positionOverview from './position/position-overview';
import professionalSummary from './professional-summary';
import remunerationGraph from './remuneration-graph';
import remunerationOverview from './remuneration-overview';
import statsWidget from './statswidget/StatsWidget.module';
import tooltip from './tooltip/Tooltip.module';
import validators from './validators';

// React component imports without Angular2React wrapper
import { InputError, InputHint } from './react/inputs';

export default angular.module('wcCommon.components', [
  autoScrollContainer.name,
  blockQuote.name,
  buttons.name,
  chips.name,
  circularImage.name,
  clamp.name,
  clampedText.name,
  companyLogo.name,
  compensationHidden.name,
  cookieBar.name,
  educationOverview.name,
  errorViews.name,
  expandableTextarea.name,
  highlightedImageBubble.name,
  icon.name,
  imageBubble.name,
  inputs.name,
  logo.name,
  logomh.name,
  memberProfile.name,
  messenger.name,
  minimalPositionOverview.name,
  missingImage.name,
  momentDurationFormatWrapper.name,
  navbar.name,
  nonExecPositionOverview.name,
  overviewCard.name,
  passwordStrengthMeter.name,
  places.name,
  positionOverview.name,
  professionalSummary.name,
  remunerationGraph.name,
  remunerationOverview.name,
  statsWidget.name,
  tooltip.name,
  validators.name
]);

// React component exports
export { InputError, InputHint };
