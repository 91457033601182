import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

export default angular
  .module('wcCommon.components.profile.personal', [])
  .component('wcMemberProfilePersonal', {
    bindings: {
      member: '<'
    },
    templateUrl: templateUrl
  });
