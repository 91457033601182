import angular from 'angular';

import './styles.scss';
import RemunerationGraphTemplateUrl from './template.html';

/*@ngInject*/
let remunerationGraph = $timeout => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: RemunerationGraphTemplateUrl,
    scope: {
      remuneration: '=',
      rowLayout: '='
    },
    link: (scope, element) => {
      function positionBars() {
        if (!scope.remuneration) {
          return;
        }

        let dimension;

        if (scope.rowLayout) {
          dimension = 'width';
        } else {
          dimension = 'height';
        }

        // Use tags instead of classes to make it work with jqLite
        let salary = element.find('remuneration-salary');
        let bonus = element.find('remuneration-bonus');
        let longTermCash = element.find('remuneration-long-term-cash');
        let stockAndOptions = element.find('remuneration-stock-and-options');
        let otherFinancial = element.find('remuneration-other');

        let legend = element.find('remuneration-legend');

        let amounts = [
          scope.remuneration.salaryAmount,
          scope.remuneration.bonusAmount,
          scope.remuneration.longTermCashAmount,
          scope.remuneration.stockAndOptionsAmount,
          scope.remuneration.otherFinancialAmount
        ];

        let totalCompensationAmount = amounts.reduce((a, b) => {
          a = parseFloat(a) || 0;
          b = parseFloat(b) || 0;
          return a + b;
        }, 0.0);

        let baseSize = 100;
        let baseUnit = '%';
        let zeroSize = '0';

        $timeout(() => {
          if (totalCompensationAmount > 0) {
            salary.css(
              dimension,
              (scope.remuneration.salaryAmount / totalCompensationAmount) *
                baseSize +
                baseUnit
            );
            bonus.css(
              dimension,
              (scope.remuneration.bonusAmount / totalCompensationAmount) *
                baseSize +
                baseUnit
            );
            longTermCash.css(
              dimension,
              (scope.remuneration.longTermCashAmount /
                totalCompensationAmount) *
                baseSize +
                baseUnit
            );
            stockAndOptions.css(
              dimension,
              (scope.remuneration.stockAndOptionsAmount /
                totalCompensationAmount) *
                baseSize +
                baseUnit
            );
            otherFinancial.css(
              dimension,
              (scope.remuneration.otherFinancialAmount /
                totalCompensationAmount) *
                baseSize +
                baseUnit
            );

            legend.css('display', 'block');
          } else {
            salary.css(dimension, zeroSize + baseUnit);
            bonus.css(dimension, zeroSize + baseUnit);
            longTermCash.css(dimension, zeroSize + baseUnit);
            stockAndOptions.css(dimension, zeroSize + baseUnit);
            otherFinancial.css(dimension, zeroSize + baseUnit);

            legend.css('display', 'none');
          }
        });
      }

      scope.$watch('remuneration', positionBars, true);
    }
  };
};

remunerationGraph.NAME = 'remunerationGraph';

export default angular
  .module('wcCommon.components.remuneration.graph', [])
  .directive('remunerationGraph', remunerationGraph);
