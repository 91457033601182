import angular from 'angular';
import clamp from '../../clamp/index';

import './styles.scss';
import templateUrl from './template.html';

class PositionOverviewController {
  constructor() {
    this.summaryExpanded = false;
    this.remunerationExpanded = false;
    this.lines = this.clampLines ? this.clampLines : 2;
    this.clampStyle = 'Clamp-' + this.lines;
  }

  expandSummary() {
    this.summaryExpanded = !this.summaryExpanded;
  }

  expandRemuneration() {
    this.remunerationExpanded = !this.remunerationExpanded;
  }
}

export default angular
  .module('wcCommon.components.position.positionOverview', [clamp.name])
  .component('wcPositionOverview', {
    bindings: {
      position: '<',
      profile: '<',
      remuneration: '<?',
      showEmpty: '@?',
      isClient: '<?',
      clampLines: '@?'
    },
    controller: PositionOverviewController,
    templateUrl: templateUrl
  });
