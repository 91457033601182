import angular from 'angular';
import './CompanyLogo.scss';
import templateUrl from './CompanyLogo.html';
import MissingImage from '../missing-image';
import { MISSING_COMPANY_LOGO } from '../../services/ImageUrls';

const dependencies = [MissingImage.name];

const CompanyLogo = {
  bindings: {
    src: '@wcSrc',
    alt: '@alt'
  },
  controller: function() {
    this.MISSING_COMPANY_LOGO = MISSING_COMPANY_LOGO;
  },
  NAME: 'wcCompanyLogo',
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.companyLogo', dependencies)
  .component(CompanyLogo.NAME, CompanyLogo);
