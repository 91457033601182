import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

class ContactsController {
  /*@ngInject*/
  constructor(API, AuthenticationService) {
    this.API = API;
    this.getDetailsButtonDisabled = false;
    this.showContact = false;
    AuthenticationService.getProfile().then(profile => {
      this.showContact = profile && !profile.hideContact;
    });
  }

  getContactDetails() {
    this.getDetailsButtonDisabled = true;
    this.API.ContactDetails(this.user.id)
      .then(this.updateUserWithContactDetails.bind(this))
      .catch(this.API.handleError.bind(this));
  }

  updateUserWithContactDetails(contactDetails) {
    Object.assign(this.user, contactDetails);
    this.getDetailsButtonDisabled = false;
  }
}

export default angular
  .module('wcCommon.components.profile.contacts', [])
  .component('wcMemberProfileContacts', {
    bindings: {
      user: '<',
      isContactable: '<?',
      isClient: '<?'
    },
    controller: ContactsController,
    templateUrl: templateUrl
  });
