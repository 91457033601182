import './BlockQuote.scss';
import BlockQuoteTemplateUrl from './BlockQuote.html';

let blockQuote = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: BlockQuoteTemplateUrl,
    scope: {
      title: '@',
      iconClass: '@',
      text: '@'
    }
  };
};

blockQuote.NAME = 'blockQuote';

export default blockQuote;
