import './CircularImage.scss';
import CircularImageTemplateUrl from './CircularImage.html';
import CircularImageModernTemplateUrl from './CircularImageModern.html';

import { MISSING_PROFILE_PICTURE_WITH_ACCENT_BACKGROUND_BASE64 } from '../../services/ImageUrls';

/*eslint angular/definedundefined:0*/
let modern = true;
if (typeof Modernizr !== 'undefined') {
  modern = Modernizr.objectfit;
}

const templateUrl = modern
  ? CircularImageModernTemplateUrl
  : CircularImageTemplateUrl;

let circularImage = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: templateUrl,
    scope: {
      alt: '@wcAlt',
      missingImageUrl: '@?wcMissingImageUrl',
      src: '@wcSrc'
    },
    link: (scope, elem) => {
      scope.missingImage =
        scope.missingImageUrl ||
        MISSING_PROFILE_PICTURE_WITH_ACCENT_BACKGROUND_BASE64;
      if (!modern) {
        elem.css({
          'background-image': `url(${scope.src || scope.missingImage})`
        });
        scope.$watch('src', () => {
          elem.css({
            'background-image': `url(${scope.src || scope.missingImage})`
          });
        });
      }
    }
  };
};

circularImage.$inject = [];
circularImage.NAME = 'wcCircularImage';

export default circularImage;
