import angular from 'angular';

import './styles.scss';

import Aspirations from './aspirations';
import CareerHistory from './career-history';
import Contacts from './contact-details';
import CandidateSummary from './candidate-summary';
import Education from './education';
import ExperienceFull from './experience-full';
import ExperienceLimited from './experience-limited';
import FinanceAndPeople from './finance-people';
import Insights from './insights';
import NonExecPositions from './non-exec-positions';
import Personal from './personal';
import RegionalManagement from './regional-management';
import WorkEligibility from './work-eligibility';
import Documents from './documents';

/*
Member Profile container component
Use this common component on all platforms to get and set the Member's profile
and handle further needed requests to API resolve the necessary details:
- Address for Own Member and Clients
- Aspirations for Own Member and Clients
- Groups for other Members
- and initialize CurrencyService

We could use a Service for this, as this hasn't anything to do with the view,
but we need this data only as long as this component's lifecycle ends.
*/

class CommonMemberProfileController {
  /*@ngInject*/
  constructor(
    API,
    $stateParams,
    CurrencyService,
    $q,
    featureFlags,
    GroupInvitesService,
    $scope,
    $window
  ) {
    this.API = API;
    this.$stateParams = $stateParams;
    this.CurrencyService = CurrencyService;
    this.$q = $q;
    this.featureFlags = featureFlags;
    this.loaded = false;
    this.member = {};
    this.GroupInvitesService = GroupInvitesService;
    this.$scope = $scope;
    this.$window = $window;

    this.promises = [];
  }

  $onInit() {
    this.handleUserType();
  }

  handleUserType() {
    if (!this.isOwn) {
      this.API.MembersData.get(this.$stateParams.id)
        .then(this.setMember.bind(this))
        .then(this.setUserFromMember.bind(this))
        .catch(this.handleError.bind(this));
    } else {
      this.API.User.get()
        .then(this.setUser.bind(this))
        .then(this.API.retrieveProfile.bind(this))
        .then(this.setMember.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  setUserFromMember(member) {
    return this.API.restangularizeUrl(member.user)
      .get()
      .then(this.setUser.bind(this));
  }

  updateLoaded() {
    this.loaded = this.user && this.member ? true : false;
  }

  setUser(user) {
    this.user = user;

    this.onUserSet({ user: this.user });

    this.updateLoaded();

    return user;
  }

  setMember(member) {
    this.member = member.status ? member.data : member;

    this.onMemberSet({ profile: this.member });

    // Address if Own or Client
    if (this.member.address && !this.member.address.url) {
      let addressPromise = this.API.restangularizeUrl(this.member.address)
        .get()
        .then(address => (this.member.address = address));
      this.promises.push(addressPromise);
    }

    // Aspirations if Own or Client
    if (this.member.aspirations) {
      let aspirationsPromise = this.API.restangularizeUrl(
        this.member.aspirations
      )
        .get()
        .then(aspirations => (this.member.aspirations = aspirations));
      this.promises.push(aspirationsPromise);
    }

    // Covisibility
    if (this.member.covisibility) {
      let covisibilityPromise = this.API.retrieveMyCovisibility().then(
        covisibility => (this.member.covisibility = covisibility)
      );
      this.promises.push(covisibilityPromise);
    }

    // Get Groups for invite button if other member (feature flag is disabled on mobile)
    if (
      this.featureFlags.isOn('memberGroups') &&
      !this.isOwn &&
      !this.isClient
    ) {
      let groupsServicePromise = this.GroupInvitesService.getGroupsICanInviteMemberTo().then(
        this.setGroups.bind(this)
      );
      this.promises.push(groupsServicePromise);
    }

    if (this.isOwn || this.isClient) {
      let currencyPromise = this.CurrencyService.isReady;
      this.promises.push(currencyPromise);
    }

    return this.$q
      .all(this.promises)
      .then(this.returnMember.bind(this))
      .catch(this.handleError.bind(this));
  }

  setGroups() {
    this.groups = this.GroupInvitesService.groups;
    if (this.onGroupsSet) {
      this.onGroupsSet({ groups: this.groups });
    }
  }

  returnMember() {
    this.updateLoaded();
    return this.member;
  }

  handleError(error) {
    this.$window.location.href = '/404';
    this.API.handleError()(error);
  }
}

export default angular
  .module('wcCommon.components.profile.member', [
    Aspirations.name,
    CandidateSummary.name,
    CareerHistory.name,
    Contacts.name,
    Documents.name,
    Education.name,
    ExperienceFull.name,
    ExperienceLimited.name,
    FinanceAndPeople.name,
    Insights.name,
    NonExecPositions.name,
    Personal.name,
    RegionalManagement.name,
    WorkEligibility.name
  ])
  .component('wcCommonMemberProfile', {
    bindings: {
      isOwn: '=?',
      isClient: '<?',
      onMemberSet: '&',
      onUserSet: '&',
      onGroupsSet: '&?'
    },
    controller: CommonMemberProfileController,
    transclude: true,
    template: `<div ng-transclude></div>`
  });
