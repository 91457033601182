import './EducationOverview.scss';
import EducationOverviewTemplateUrl from './EducationOverview.html';

let educationOverview = () => {
  return {
    restrict: 'E',
    templateUrl: EducationOverviewTemplateUrl,
    replace: true,
    scope: {
      educations: '='
    }
  };
};

educationOverview.NAME = 'educationOverview';

export default educationOverview;
