import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class ClampedTextController {
  /*@ngInject*/
  constructor() {
    this.expanded = false;
  }
}

export default angular
  .module('wc.components.inputs.clampedText', [])
  .component('wcClampedText', {
    bindings: {
      lines: '@?',
      clampStyle: '@?',
      text: '<?'
    },
    controller: ClampedTextController,
    templateUrl: templateUrl
  });
