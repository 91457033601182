function isChildOf(region1, region2) {
  return !!(region1.superRegion && region1.superRegion === region2.url);
}

function hasParent(eligibleRegions, region) {
  return !!eligibleRegions.find(er => isChildOf(region, er));
}

function keepRegion(eligibleRegions, region) {
  return !hasParent(eligibleRegions, region);
}

export function removeChildRegions(eligibleRegions) {
  return eligibleRegions.filter(region => keepRegion(eligibleRegions, region));
}
