import './QAndA.scss';
import QAndATemplateUrl from './QAndA.html';

let qAndA = () => {
  return {
    restrict: 'E',
    templateUrl: QAndATemplateUrl,
    scope: {
      answer: '@',
      question: '@',
      separator: '<?'
    }
  };
};

qAndA.NAME = 'qAndA';

export default qAndA;
