import store from 'store';
import Raven from 'raven-js';

import { ActionTypes } from './actions';
import createReducer from '../reducer';

const loadTokenFromStorage = () => {
  let tokenState = undefined;
  try {
    tokenState = store.get('ngStorage-sessionid');
  } catch (err) {
    Raven.captureException(err);
  }
  return tokenState;
};

const clearTokenFromStorage = () => {
  try {
    store.remove('ngStorage-sessionid');
  } catch (err) {
    Raven.captureException(err);
  }
};

const initialState = {
  sessionId: loadTokenFromStorage()
};

const setSession = (state, action) => {
  return Object.assign({}, state, {
    sessionId: action.sessionId
  });
};

const clearSession = state => {
  clearTokenFromStorage();
  return Object.assign({}, state, {
    sessionId: undefined
  });
};

const handlers = {
  [ActionTypes.CLEAR_STORE]: clearSession,
  [ActionTypes.SET_SESSION]: setSession,
  [ActionTypes.CLEAR_SESSION]: clearSession
};

const tokenReducer = (state = initialState, action) => {
  return createReducer(state, action, handlers);
};

export default tokenReducer;
