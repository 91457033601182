export default function getFormattedDateString(date) {
  if (!date) {
    return null;
  }
  if (date.getFullYear) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(
      -2
    )}-15`;
  }
  return date; // If the data was loaded from the server and was not modified
}
