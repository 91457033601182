export default class NewMessageFormController {
  /*@ngInject*/
  constructor($element, $timeout, ChatService, MessengerService, $document) {
    this.$element = $element;
    this.$document = $document;
    this.$timeout = $timeout;
    this.ChatService = ChatService;
    this.MessengerService = MessengerService;

    this.sendMessage = this.sendMessage.bind(this);
  }

  focusOnInput(cls) {
    let elements = this.$document[0].getElementsByClassName(cls);
    if (elements.length) {
      elements[0].focus();
    }
  }

  sendMessage() {
    this.ChatService.sendSimpleMessage(
      this.MessengerService.currentConversation,
      this.message
    );
    this.message = '';
  }

  $postLink() {
    this.$timeout(() => {
      this.$element.find('textarea').focus();
    });
  }
}
