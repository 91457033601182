import angular from 'angular';

import templateUrl from './template.html';

import { sortNonExecPositions } from '../../../services/Positions';

export default angular
  .module('wcCommon.components.profile.member.nonExecPositions', [])
  .component('wcMemberProfileNonExecPositions', {
    bindings: {
      member: '<'
    },
    controller: function() {
      this.$onInit = function() {
        this.member.nonExecPositions = sortNonExecPositions(
          this.member.nonExecPositions
        );
      };
    },
    templateUrl: templateUrl
  });
