import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

class MemberProfileDocumentsController {
  /*@ngInject*/
  constructor(DocumentDownloadService) {
    this.DocumentDownloadService = DocumentDownloadService;
  }

  download(doc) {
    this.DocumentDownloadService.download(doc, 'candidatedocuments');
  }
}

export default angular
  .module('wcCommon.components.profile.member.documents', [])
  .component('wcMemberProfileDocuments', {
    controller: MemberProfileDocumentsController,
    bindings: {
      member: '<',
      longlist: '<',
      documents: '<'
    },
    templateUrl: templateUrl
  });
