import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

import { sortPositions } from '../../../services/Positions';

class MemberProfileCareerHistoryController {
  /*@ngInject*/
  constructor() {
    this.positionsExpanded = false;
  }

  $onInit() {
    this.member.positions = sortPositions(this.member.positions);
  }

  getRemuneration(position) {
    if (position.current) {
      return this.member.remuneration;
    }
    if (position.totalCompensationCurrency) {
      return position;
    }
  }

  toggleExpandPositions() {
    this.positionsExpanded = !this.positionsExpanded;
  }
}

export default angular
  .module('wcCommon.components.profile.member.careerHistory', [])
  .component('wcMemberProfileCareerHistory', {
    bindings: {
      member: '<',
      isClient: '<?'
    },
    controller: MemberProfileCareerHistoryController,
    templateUrl: templateUrl
  });
