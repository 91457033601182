import angular from 'angular';
import moment from 'moment';

import './styles.scss';
import templateUrl from './template.html';

class NonExecPositionOverviewController {
  dateDiff(start, end = {}) {
    let diff = moment(end).diff(moment(start), true);
    if (diff < 0) {
      diff *= -1;
    }
    let duration = moment.duration(diff);
    return duration.format();
  }
}

export default angular
  .module('wcCommon.components.position.nonExecPositionOverview', [])
  .component('wcNonExecPositionOverview', {
    bindings: {
      isOwn: '@?',
      position: '<'
    },
    controller: NonExecPositionOverviewController,
    templateUrl: templateUrl
  });
