import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

export default angular
  .module('wcCommon.components.profile.candidateSummary', [])
  .component('wcMemberProfileCandidateSummary', {
    bindings: {
      summary: '<?'
    },
    templateUrl: templateUrl
  });
