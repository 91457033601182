import './ImageBubble.scss';
import ImageBubbleTemplateUrl from './ImageBubble.html';
import { MISSING_IMAGE_URL } from '../../../common/services/ImageUrls';

let imageBubble = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: ImageBubbleTemplateUrl,
    scope: {
      src: '=',
      caption: '@'
    },
    link: scope => {
      scope.missingImage = MISSING_IMAGE_URL;
    }
  };
};

imageBubble.NAME = 'imageBubble';

export default imageBubble;
