import './ImageBubbleList.scss';
import ImageBubbleListTemplateUrl from './ImageBubbleList.html';

let imageBubbleList = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: ImageBubbleListTemplateUrl,
    scope: {
      data: '=',
      sourceField: '@',
      captionField: '@'
    },
    link: scope => {
      if (!scope.sourceField) {
        scope.sourceField = 'src';
      }
      if (!scope.captionField) {
        scope.captionField = 'caption';
      }
    }
  };
};

imageBubbleList.NAME = 'imageBubbleList';

export default imageBubbleList;
